body {
  background: #F9F9F9;
  font-family: 'Poppins', sans-serif;
  height:100vh
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
img.preview-img {
  width: 210px;
  height: 210px;
  position: absolute;
  border-radius: 50%;
  object-fit: cover;  
}

.bg-custom-gray {
  background-color: #efefef;
}

@media only screen and (max-width: 480px) {
  .header-right .user-details {
  display: none;
}
}

@media only screen and (max-width: 767px) {
  /* Apply styles for mobile devices */
  .right-side-div {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; /* Optional: For smoother scrolling on iOS devices */
    white-space: nowrap; /* Prevent wrapping of elements */
  }

  .dash-box {
    /* You may need to adjust the width of .dash-box elements to fit the mobile screen */
    width: 700px; /* Example width */
    
  }
  .modal-content {
    width: 100%;
  }
.HomeMainDiv{
  
width: 767px;
overflow: auto;;

}
.mobile-image{
  width: 80px;
  height: 70px;
}
.HomeMainDiv.report-request{
  overflow: inherit;
  
}


}

.flex.items-center.justify-center.pt-15.pb-19.mt-5.mb-3.pagination {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 20px;
}



.dashboard-inner-main{
height: 100vh;

}

.float-right.support-request{
  margin-top: -40px;
  float: right;
}

.styled-select {
  /* background-size: 30px; */
  /* width: 100%; */
  /* height: 45px; */
  background: url(../src/assets/images/arrow.png) no-repeat right white;
  position: relative;
  appearance: none;
  background-position-x: 92%;
}


/* Add this CSS code to limit text on smaller screens */
@media only screen and (max-width: 768px) {
  .user-info h4 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.react-tel-input .country-list input.search-box {
  width: 95%;
  border: 1px solid #000;
  height: 35px;
}