@tailwind base;
@tailwind components;
@tailwind utilities;



.active,
.active > li {
  /* Add whatever CSS style will make your link and li look active. Adjust based on your design */
  @apply text-white bg-black rounded;
}

:root {
  --yellow-color: #f1c40f; /* Replace with your desired yellow color */
  --blue-color: #3498db;   /* Replace with your desired blue color */
  /* ... other variables ... */
}
 

.p-multiselect-header {
  display: none;
}
.p-checkbox.p-component {
  width: 20px;
  height: 20px;
  background: #fff;
  display: inline-block;
  border-radius: 4px;
  margin-right: 10px;
  border: 1px solid #b7b2b2;
}


.max-lines {
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
}

.ReactCrop__child-wrapper {
  overflow: hidden;
  max-height: inherit;
  height: 100%;
}



/* ////////////////////////////////////////////  CLASS FOR LABEL ///////////////////////////////////////////////////////// */
/* styles.css */
.label {
  position: absolute;
  font-size: 1.25rem; /* equivalent to text-lg */
  color: #757575; /* equivalent to text-gray-500 */
  transition-duration: 0.3s; /* equivalent to duration-300 */
  transform: translateY(-1rem) scale(0.75); /* equivalent to -translate-y-4 scale-75 */
  top: 0.5rem; /* equivalent to top-2 */
  z-index: 10;
  background-color: #fff; /* equivalent to bg-white */
  padding: 0 0.5rem; /* equivalent to px-2 */
}

.label:focus {
  padding: 0 0.5rem; /* equivalent to peer-focus:px-2 */
  color: #3b82f6; /* equivalent to peer-focus:text-blue-600 */
}

.label::placeholder {
  transform: translateY(-50%) scale(1); /* equivalent to peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 */
  top: 50%; /* equivalent to peer-placeholder-shown:top-1/2 */
}

.label:focus::placeholder {
  transform: translateY(-1rem) scale(0.75); /* equivalent to peer-focus:scale-75 peer-focus:-translate-y-4 */
  top: 0.5rem; /* equivalent to peer-focus:top-2 */
}

/* ////////////////////////////////////////////  CLASS FOR LABEL ///////////////////////////////////////////////////////// */

.react-tel-input .selected-flag:focus::before, .react-tel-input .selected-flag.open::before{box-shadow: none !important;border: 0 !important;}
.react-tel-input .country-list .country.highlight {background-color: #000 !important;}
.react-tel-input .country-list .country.highlight .country-name{color: #fff;}
.country.preferred.active{background: none;} 
.country.preferred.active .country-name{color: #000;}
.country.preferred.active .dial-code{  color: #000;}

.react-tel-input .country-list{
  position: relative !important;
   width: auto !important;
}